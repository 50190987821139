<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 5.00001L14.7127 2.38621C15.3543 2.19373 16 2.67418 16 3.34404V5.00001H6ZM2 8.00001C2 7.44772 2.44772 7.00001 3 7.00001H21C21.5523 7.00001 22 7.44772 22 8.00001V11H19C17.3431 11 16 12.3432 16 14C16 15.6569 17.3431 17 19 17H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V8.00001ZM5 17C4.44772 17 4 17.4477 4 18C4 18.5523 4.44772 19 5 19H8C8.55228 19 9 18.5523 9 18C9 17.4477 8.55228 17 8 17H5ZM19 13H22V15H19C18.4477 15 18 14.5523 18 14C18 13.4477 18.4477 13 19 13Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
