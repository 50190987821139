<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M11 9.47V11H14.76L13 14.53V13H9.24L11 9.47ZM13 1L6 15H11V23L18 9H13V1Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
