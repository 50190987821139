<template>
    <AppIcon v-bind="$props">
        <!-- eslint-disable max-len -->
        <path
            d="M11.3881 8.1312L9.85151 15.3151H7.99289L9.52965 8.1312H11.3881ZM19.2071 12.7699L20.1853 10.0722L20.7483 12.7699H19.2071ZM21.2815 15.3151H23L21.4986 8.1312H19.9134C19.5561 8.1312 19.255 8.33834 19.1217 8.65778L16.333 15.3151H18.2849L18.6723 14.2422H21.0564L21.2815 15.3151ZM16.4298 12.9698C16.4379 11.0738 13.8089 10.9688 13.8265 10.1217C13.8321 9.86428 14.0777 9.59008 14.6145 9.51996C14.8806 9.4857 15.615 9.45791 16.4474 9.84149L16.773 8.3175C16.3259 8.15576 15.7505 8 15.0346 8C13.1973 8 11.9048 8.97593 11.8945 10.3745C11.8827 11.4088 12.8177 11.9855 13.5208 12.3299C14.2456 12.682 14.4884 12.9077 14.4849 13.2223C14.4799 13.7043 13.9069 13.9178 13.3734 13.9258C12.4385 13.9404 11.8966 13.6731 11.4647 13.4718L11.1273 15.0467C11.5623 15.2458 12.3637 15.419 13.1936 15.4279C15.1469 15.4279 16.424 14.4634 16.4298 12.9698ZM8.73243 8.1312L5.72112 15.3151H3.75683L2.27484 9.58184C2.18501 9.22928 2.10664 9.09969 1.83341 8.95072C1.38649 8.70803 0.648732 8.48101 0 8.3398L0.0439495 8.1312H3.20633C3.60915 8.1312 3.97156 8.39926 4.06382 8.86331L4.84666 13.0207L6.77993 8.1312H8.73243Z"
            fill="#1434CB"
        />
        <!-- eslint-enable max-len -->
    </AppIcon>
</template>
