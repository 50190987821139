<template>
    <span :class="$style['discount']">
        <slot/>
    </span>
</template>

<style
    lang="sass"
    module
    scoped
>
    .discount
        @apply font-medium p-1.5 rounded-lg text-center text-text/green bg-background/success
</style>
