<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18 4H6V20H18V4ZM6 2C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V4C20 2.89543 19.1046 2 18 2H6Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->

            <path
                d="M8 13H16V15H8V13Z"
                :fill="color"
            />

            <path
                d="M8 16H13V18H8V16Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
