<script
    lang="ts"
    setup
>
    type Emit = {
        (event: 'go-to-tariff-selection'): void
    }

    const emit = defineEmits<Emit>()
</script>

<template>
    <div class="flex flex-col items-center gap-8 pt-[60px] tablet:pt-[120px]">
        <div class="w-full max-tablet:mb-3">
            <img
                class="mx-auto"
                src="~/assets/img/pro/title-1.png"
                srcset="~/assets/img/pro/title-1@2x.png 2x"
                alt="Case 1"
            />
        </div>

        <div
            class="
                max-tablet:px-4
                tablet:mt-2
                text-[24px]
                tablet:text-[40px]
                leading-[120%]
                font-medium
                text-center
                tablet:uppercase
                max-w-[800px]
                tracking-tighter
            "
        >
            {{ $t('billing-part-1') }}
        </div>

        <AppButton
            large
            @click="emit('go-to-tariff-selection')"
        >
            {{ $t('billing-part-2') }}
        </AppButton>
    </div>
</template>
