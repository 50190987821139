<script
    lang="ts"
    setup
>
    import { useBillingStore } from '~/stores/billing'

    definePageMeta({
        middleware: 'auth'
    })

    const visualPresentationRef = ref<ReturnType<typeof defineComponent> | undefined>()
    const featuresRef = ref<ReturnType<typeof defineComponent> | undefined>()
    const tariffSelectionRef = ref<ReturnType<typeof defineComponent> | undefined>()

    const billingStore = useBillingStore()

    await billingStore.fillState()

    const route = useRoute()

    if ('bank-card-added' in route.query) {
        onMounted(() => {
            useNotify()
                .push({
                    type: 'success',
                    text: useLang().t('card-was-added-successfully')
                })

            setTimeout(() => {
                (tariffSelectionRef.value.$refs.purchaseStepMethodRef.$refs.methodItemCardRef.$el as HTMLElement)
                    .scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
            }, 200)

            navigateTo({
                query: {},
                replace: true
            })
        })
    }

    const showPromo = ref<boolean>(!!billingStore.activePlan)
    const showTitle = ref<boolean>(!billingStore.activePlan)
    const showVisualPresentation = ref<boolean>(!billingStore.activePlan)
    const showFeatures = ref<boolean>(!billingStore.activePlan)

    const handleScrollAnimation = (event): void => {
        visualPresentationRef.value?.scrollAnimations
            .forEach((item, key) => {
                if (event.target.scrollTop < item.scrollPosition) {
                    return
                }

                if (item.style) {
                    item.el.setAttribute('style', item.style)
                }

                if (item.class) {
                    item.el.classList.add(item.class)
                }

                visualPresentationRef.value.scrollAnimations.splice(key, 1)
            })
    }

    const goToFeatures = (): void => {
        showPromo.value = false

        showVisualPresentation.value = showTitle.value = showFeatures.value = true

        nextTick(() => setTimeout(() => {
            featuresRef.value.$refs.titleRef
                .scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                })
        }, 200))
    }

    const goToTariffSelection = (): void => {
        tariffSelectionRef.value.$refs.titleRef
            .scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
    }
</script>

<template>
    <AppPage @scroll.passive="handleScrollAnimation">
        <Title>
            {{ $t('subscription') }}
        </Title>

        <div
            class="
                tablet:container
                flex
                flex-col
                mx-auto
                px-4
                tablet:px-6
                desktop:px-[140px]
                tablet:max-w-[1356px]
            "
        >
            <BillingPromo
                v-if="showPromo"
                key="promo"
                class="mt-4 tablet:mt-6 desktop:mt-15"
                @go-to-features="goToFeatures()"
            />

            <BillingTitle
                v-if="showTitle"
                key="title"
                class="mb-20 tablet:mb-32"
                @go-to-tariff-selection="goToTariffSelection()"
            />

            <BillingVisualPresentation
                v-if="showVisualPresentation"
                key="section-1"
                ref="visualPresentationRef"
            />

            <BillingFeatures
                v-if="showFeatures"
                key="section-2"
                ref="featuresRef"
            />

            <BillingActivePlan key="section-3"/>

            <BillingTariffSelection
                key="section-4"
                ref="tariffSelectionRef"
            />

            <BillingPaymentTable key="section-5"/>
        </div>
    </AppPage>
</template>
