<script
    lang="ts"
    setup
>
    import { useBillingStore } from '~/stores/billing'

    type Emit = {
        (event: 'set-empty-card-list-error', value: boolean): void
    }

    const emit = defineEmits<Emit>()

    const billingStore = useBillingStore()

    const widget = useWidget(useRuntimeConfig().public.widgetSupportCode, { preferablyOpenChat: true })

    widget.onSetup((sdk) => {
        sdk('setZIndex', 100)
        sdk('onBeforeWidgetClose', widget.hideWidget)
    })

    const pending = ref<boolean>(false)

    const planOperatorsPriceSum = computed<number>(() => {
        return billingStore.selectedOperatorQuantity * +billingStore.selectedPlan.pricePerOperator
    })

    const planTotalDiscountSum = computed<number>(() => {
        return planTotalSumWithoutDiscount.value - planTotalSum.value
    })

    const planTotalSumWithoutDiscount = computed<number>(() => {
        return billingStore.selectedPlan.duration * planOperatorsPriceSum.value
    })

    const planTotalSum = computed<number>(() => {
        return planTotalSumWithoutDiscount.value
            - (planTotalSumWithoutDiscount.value * billingStore.selectedPlan.discount / 100)
    })

    const openSupportWidget = (): void => {
        widget.showWidget('support')
    }

    const toFixed = (value): string => {
        return parseFloat(value).toFixed(2)
    }

    const purchase = async (): Promise<void> => {
        if (pending.value) {
            return
        }

        if (billingStore.purchaseMethod === 'card' && !billingStore.selectedCardId) {
            emit('set-empty-card-list-error', true)

            return
        }

        pending.value = true

        try {
            await billingStore.purchase()
        } catch (error) {
            pending.value = false

            return
        }

        pending.value = false
    }
</script>

<template>
    <BillingCard
        class="w-full p-6 font-medium"
        :animation-class="false"
    >
        <div class="mb-2 flex text-base">
            {{ $t('operators') }}

            <span class="ml-auto">
                {{ billingStore.selectedOperatorQuantity }} х
                ${{ toFixed(billingStore.selectedPlan.pricePerOperator) }}
            </span>
        </div>

        <div class="flex">
            {{ $t('billing-part-83') }}

            <span class="ml-auto">
                {{ billingStore.selectedPlan.duration }} х ${{ toFixed(planOperatorsPriceSum) }}
            </span>
        </div>

        <template v-if="billingStore.selectedPlan.duration > 1">
            <hr class="my-3.5">

            <div class="flex items-center">
                {{ $t('discount') }}

                <BillingPurchaseDiscount class="ml-auto">
                    {{ $t('billing-part-84') }} ${{ toFixed(planTotalDiscountSum) }}
                </BillingPurchaseDiscount>
            </div>
        </template>

        <hr class="my-3.5">

        <div class="flex tablet:items-center justify-between">
            {{ $t('total') }}:

            <div class="flex flex-col tablet:flex-row items-end tablet:items-center gap-3">
                <span
                    v-if="billingStore.selectedPlan.duration > 1"
                    class="line-through text-text/secondary"
                >
                    ${{ toFixed(planTotalSumWithoutDiscount) }}
                </span>

                <span class="text-[2.5rem]">
                    ${{ toFixed(planTotalSum) }}
                </span>
            </div>
        </div>

        <AppButton
            large
            class="my-8 w-full"
            :loading="pending"
            @click="purchase()"
        >
            {{ $t('billing-part-85') }} ${{ toFixed(planTotalSum) }}
        </AppButton>

        <div class="text-center font-normal leading-snug">
            {{ $t('billing-part-86') }}

            <a
                href="#"
                class="underline"
                @click="openSupportWidget()"
            >
                {{ $t('billing-part-87') }}
            </a>
        </div>
    </BillingCard>
</template>
