<script
    lang="ts"
    setup
>
    type Props = {
        animationClass?: string | false
    }

    const props = withDefaults(defineProps<Props>(), {
        animationClass: 'translate-y-2'
    })

    const generatedAnimationClass = props.animationClass ? `${ props.animationClass } opacity-0` : ''
</script>

<template>
    <div :class="[ $style['card'], generatedAnimationClass ]">
        <slot/>
    </div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .card
        @apply bg-background/light-stone rounded-2xl flex flex-col

        transition: 0.5s opacity linear, 0.5s transform linear
</style>
