<script
    lang="ts"
    setup
>
    import { InvoiceStatusEnum } from '~/ts/enums/invoice'
    import { useBillingStore } from '~/stores/billing'

    const billingStore = useBillingStore()

    const { t } = useLang()
    const { maxTablet } = useWindowSize()

    const headers = computed<string[]>(() => {
        if (maxTablet.value) {
            return [
                t('date'),
                t('title'),
                t('bill')
            ]
        }

        return [
            t('date'),
            t('title'),
            t('status'),
            t('billing-part-41'),
            t('sum'),
            t('bill')
        ]
    })

    const statusNamesByType = computed(() => ({
        [InvoiceStatusEnum.New]: t('not-paid'),
        [InvoiceStatusEnum.Paid]: t('paid'),
        [InvoiceStatusEnum.Canceled]: t('cancelled')
    }))

    provide('invoiceStatusNamesByType', statusNamesByType)

    const rows = computed(() => billingStore.invoices.map((item) => {
        if (maxTablet.value) {
            return [
                dateUtil.fromSeconds(item.created_at).toFormat(DATE_DISPLAY_DATE_FORMAT),
                'PRO - ' + billingStore.planDurationLabelsByValue[item.invoiceItems[0].plan.duration],
                t('view')
            ]
        }

        return [
            dateUtil.fromSeconds(item.created_at).toFormat(DATE_DISPLAY_DATE_FORMAT),
            'PRO - ' + billingStore.planDurationLabelsByValue[item.invoiceItems[0].plan.duration],
            statusNamesByType.value[item.status],
            item.invoiceItems[0].quantity,
            item.amount + ' ' + item.currency,
            t('view')
        ]
    }))

    const rowIndexAction = computed<number>(() => {
        return maxTablet.value ? 2 : 5
    })

    const getValueClass = (rowIndex: any, valueIndex: number): string => {
        if (valueIndex !== 2) {
            return undefined
        }

        return {
            [InvoiceStatusEnum.New]: 'text-text/warning',
            [InvoiceStatusEnum.Paid]: 'text-text/green',
            [InvoiceStatusEnum.Canceled]: 'text-text/disabled'
        }[billingStore.invoices[rowIndex].status]
    }
</script>

<template>
    <div
        key="title"
        class="mt-16 tablet:my-24 mb-6 tablet:mb-10 text-[20px] tablet:text-[40px] font-medium"
    >
        {{ $t('billing-part-31') }}
    </div>

    <div
        v-if="rows.length"
        key="table-head"
        class="billing-payment-table-row text-text/secondary mb-8 text-sm"
    >
        <div
            v-for="header in headers"
            :key="header"
        >
            {{ header }}
        </div>
    </div>

    <div
        key="table-body"
        class="billing-payment-table-row text-sm"
    >
        <template
            v-for="(row, rowIndex) in rows"
            :key="'row-' + rowIndex"
        >
            <template
                v-for="(value, valueIndex) in row"
                :key="'value-' + valueIndex"
            >
                <div
                    v-if="valueIndex === rowIndexAction"
                    key="action"
                >
                    <AppLink
                        wrapper
                        :to="{
                            name: 'p-pid-billing-invoice-id',
                            params: { id: billingStore.invoices[rowIndex].id }
                        }"
                    >
                        <AppButton
                            secondary
                            small
                        >
                            {{ value }}
                        </AppButton>
                    </AppLink>
                </div>

                <div
                    v-else
                    key="value"
                    :class="getValueClass(rowIndex, valueIndex)"
                >
                    {{ value }}
                </div>
            </template>
        </template>

        <div
            v-if="!rows.length"
            key="empty"
            class="col-span-3 text-center mb-8 text-base text-text/secondary"
        >
            {{ $t('billing-part-82') }}
        </div>
    </div>

    <AppTablePagination
        v-if="rows.length"
        key="pagination"
        v-bind="billingStore.invoicePagination"
        :pending="billingStore.invoicePaginationPending"
        class="!mt-10 tablet:!mt-14 !mb-[80px]"
        @go-to-page="billingStore.paginateInvoices"
    />

    <!-- TODO Замінити на <NuxtPage/> після вирішення https://github.com/nuxt/nuxt/issues/23232 -->
    <RouterView
        key="page"
        v-slot="{ Component }"
    >
        <AppTransitionSlideXMobileOnly>
            <component :is="Component"/>
        </AppTransitionSlideXMobileOnly>
    </RouterView>
</template>

<style
    lang="sass"
    scoped
>
    .billing-payment-table-row
        @apply grid grid-cols-[90px_2fr_minmax(74px,max-content)] tablet:grid-cols-[repeat(5,1fr),minmax(74px,max-content)] gap-x-2 gap-y-12
</style>
