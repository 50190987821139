<script
    lang="ts"
    setup
>
    type Emit = {
        (event: 'go-to-features'): void
    }

    const emit = defineEmits<Emit>()
</script>

<template>
    <div class="bg-background/light-stone rounded-[1.25rem] relative flex max-tablet:flex-col overflow-hidden">
        <div class="py-6 px-4 tablet:py-10 tablet:px-12">
            <div class="text-[20px] tablet:text-[24px] leading-[120%] font-medium mb-6 max-w-[420px]">
                {{ $t('billing-part-32') }}
            </div>

            <AppButton @click="emit('go-to-features')">
                {{ $t('billing-part-33') }}
            </AppButton>
        </div>

        <div class="tablet:absolute right-0 tablet:flex justify-end h-[228px] tablet:h-full z-0 max-lg:-right-1/2">
            <img
                class="object-right-top tablet:object-right object-cover max-tablet:h-full"
                src="~/assets/img/pro/promo.png"
                srcset="~/assets/img/pro/promo@2x.png 2x"
                alt="All Features"
            />
        </div>
    </div>
</template>
