<script
    lang="ts"
    setup
>
    import { useBillingStore } from '~/stores/billing'

    const billingStore = useBillingStore()

    const purchaseStepMethodRef = ref<ReturnType<typeof defineComponent>>()

    const showEmptyCardListError = ref<boolean>(false)

    const planOperatorDecrease = (): void => {
        if (billingStore.selectedOperatorQuantity === 1) {
            return
        }

        billingStore.selectedOperatorQuantity--
    }

    const planOperatorIncrease = (): void => {
        if (billingStore.selectedOperatorQuantity >= 20) {
            return
        }

        billingStore.selectedOperatorQuantity++
    }

    const setShowEmptyCardListError = (value: boolean): void => {
        showEmptyCardListError.value = value

        purchaseStepMethodRef.value.$refs.methodItemCardRef.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
        })
    }
</script>

<template>
    <div
        ref="titleRef"
        class="mt-16 tablet:mt-28 mb-4 text-[20px] tablet:text-[40px] font-medium"
    >
        {{ $t('billing-part-27') }}
    </div>

    <div class="flex flex-col tablet:flex-row">
        <!-- purchase calculator -->
        <div class="flex flex-col gap-2 tablet:basis-[50%] tablet:mt-6 tablet:pr-10">
            <!-- operators count -->
            <BillingPurchaseStep>
                <template #icon>
                    <AppIconAccountGroup
                        size="19"
                        color="#000"
                    />
                </template>

                <template #title>
                    {{ $t('billing-part-28') }}
                </template>

                <div class="flex gap-6 mb-10">
                    <button
                        type="button"
                        :class="$style['control-button']"
                        :disabled="billingStore.selectedOperatorQuantity <= 1"
                        @click="planOperatorDecrease()"
                    >
                        -
                    </button>

                    <div class="flex items-center justify-center text-xl font-medium w-[25px]">
                        <span>{{ billingStore.selectedOperatorQuantity }}</span>
                    </div>

                    <button
                        type="button"
                        :class="$style['control-button']"
                        :disabled="billingStore.selectedOperatorQuantity >= 20"
                        @click="planOperatorIncrease()"
                    >
                        +
                    </button>
                </div>
            </BillingPurchaseStep>

            <!-- plan duration -->
            <BillingPurchaseStep>
                <template #icon>
                    <AppIconCalendar
                        size="19"
                        color="#000"
                    />
                </template>

                <template #title>
                    {{ $t('billing-part-29') }}
                </template>

                <div class="grid grid-cols-4 gap-4 mb-12">
                    <div
                        v-for="plan in billingStore.plans"
                        :key="plan.id"
                        class="flex flex-col gap-3 tablet:gap-4"
                    >
                        <input
                            :id="`plan-duration-${ plan.id }`"
                            v-model="billingStore.selectedPlan"
                            :value="plan"
                            type="radio"
                            name="option"
                            class="hidden peer"
                            :checked="plan.id === billingStore.selectedPlan.id"
                        />

                        <!-- eslint-disable max-len -->
                        <label
                            :for="`plan-duration-${ plan.id }`"
                            class="
                                flex
                                items-center
                                text-center
                                justify-center
                                min-h-[54px]
                                max-tablet:px-4
                                border
                                border-secondary/main
                                bg-transparent
                                rounded-lg
                                cursor-pointer
                                select-none
                                true-word-breaks
                                peer-checked:plan-duration
                            "
                        >
                            {{ plan.label }}
                        </label>
                        <!-- eslint-enable max-len -->
                        <BillingPurchaseDiscount class="mx-auto inline-flex">
                            {{ plan.discount ? '-' : '' }}{{ plan.discount }}%
                        </BillingPurchaseDiscount>
                    </div>
                </div>
            </BillingPurchaseStep>

            <!-- purchase method -->
            <BillingPurchaseStep :border="false">
                <template #icon>
                    <AppIconWallet
                        size="19"
                        color="#000"
                    />
                </template>

                <template #title>
                    {{ $t('billing-part-30') }}
                </template>

                <BillingPurchaseStepMethod
                    ref="purchaseStepMethodRef"
                    :show-empty-card-list-error="showEmptyCardListError"
                />
            </BillingPurchaseStep>
        </div>

        <!-- total + purchase -->
        <BillingPurchaseCart
            class="self-start tablet:basis-[50%] mt-10 tablet:mt-6 tablet:max-w-[375px] tablet:mx-auto"
            @set-empty-card-list-error="setShowEmptyCardListError"
        />
    </div>
</template>

<style lang="sass">
    // active duration tab button
    .peer:checked ~ .peer-checked\:plan-duration
        @apply bg-secondary/main font-medium
</style>

<style
    lang="sass"
    module
    scoped
>
    .control-button
        @apply flex items-center justify-center w-[44px] h-[44px] bg-background/light-stone rounded-lg text-[38px] pb-[3px]

        &:hover:not(:disabled)
            background-color: #f4f4f4

        &:disabled
            color: #e0e2ea
            cursor: not-allowed
</style>
