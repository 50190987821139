<script
    lang="ts"
    setup
>
    const carouselPage = ref<number>(0)
    const carouselUrls: string[] = [
        'https://media.giphy.com/media/xTiIzJSKB4l7xTouE8/giphy-downsized.gif',
        'https://media.giphy.com/media/G3Hu8RMcnHZA2JK6x1/giphy-downsized.gif',
        'https://media.giphy.com/media/tkApIfibjeWt1ufWwj/giphy.gif',
        'https://media.giphy.com/media/xT9IgG50Fb7Mi0prBC/giphy-downsized.gif'
    ]

    const gifPrev = (): void => {
        if (carouselPage.value === 0) {
            return
        }

        carouselPage.value++
    }

    const gifNext = (): void => {
        if (-carouselPage.value >= (carouselUrls.length - 2)) { // 2 элемента видно по умолчанию
            return
        }

        carouselPage.value--
    }
</script>

<template>
    <div
        class="flex gap-x-4 transition-transform"
        :style="{ transform: 'translateX' + '(' + carouselPage * 222 + 'px' + ')' }"
    >
        <img
            v-for="gifUrl in carouselUrls"
            :key="gifUrl"
            class="rounded-2xl object-cover object-center"
            width="210"
            height="188"
            :src="gifUrl"
            loading="lazy"
            alt="Giphy"
            style="min-height: 209px"
        />
    </div>

    <div class="my-6 flex justify-end gap-7">
        <button
            type="button"
            class="cursor-pointer border-0 bg-transparent p-2 hover:opacity-80"
            @click="gifPrev()"
        >
            <AppIconArrowRight
                size="24"
                class="rotate-180"
            />
        </button>

        <button
            type="button"
            class="cursor-pointer border-0 bg-transparent p-2 hover:opacity-80"
            @click="gifNext()"
        >
            <AppIconArrowRight size="24"/>
        </button>
    </div>
</template>
