<script
    lang="ts"
    setup
>
    type Props = {
        border?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        border: true
    })

    const contentBorderClass: string = props.border ? 'border-secondary/main' : 'border-transparent'
</script>

<template>
    <div class="flex flex-row items-center">
        <span class="inline-flex h-9 w-9 items-center justify-center rounded-lg bg-background/stone">
            <slot name="icon"/>
        </span>

        <span class="ml-4 font-medium">
            <slot name="title"/>
        </span>
    </div>

    <div :class="[ contentBorderClass, 'flex flex-col gap-4 ml-4 py-2 desktop:pt-4 desktop:pb-9 pl-8 border-l' ]">
        <slot/>
    </div>
</template>
